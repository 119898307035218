import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import Button from '../elements/Button';
import logo from '../../assets/images/logo.svg'; // Importovanje logo-a

const propTypes = {
  ...SectionProps.types,
  split: PropTypes.bool
}

const defaultProps = {
  ...SectionProps.defaults,
  split: false
}

class Cta extends React.Component {

  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,      
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      split,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'cta section center-content-mobile reveal-from-bottom',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'cta-inner section-inner center-content', 
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider',
    );

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            <div className="cta-content">
              <h3 className="m-0">
                Add your token for FREE to RXC Games project and add usecase to your project
              </h3>
              <div className="cta-action" style={{ marginTop: '20px' }}>
                <Button className="btn-play" tag="a" color="primary" wideMobile href="https://t.me/rxcgames">
                  Contact Us
                </Button>
              </div>
              {/* Dodavanje logo-a ispod dugmeta */}
              <div style={{ marginTop: '20px' }}>
                <img src={logo} alt="RXC Games Logo" style={{ maxWidth: '250px' }} />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

Cta.propTypes = propTypes;
Cta.defaultProps = defaultProps;

export default Cta;
