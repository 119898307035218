import React, { useState, useEffect } from 'react';
import Web3 from 'web3';
import './StakeWalletBalance.css'; // Koristimo isti CSS fajl za stilizaciju

const web3 = new Web3("https://bsc-dataseed1.binance.org:443");
const tokenABI = [
  {
    constant: true,
    inputs: [{ internalType: "address", name: "account", type: "address" }],
    name: "balanceOf",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  // Dodajte ostatak vašeg ABI-ja ako je potreban
];
const tokenAddress = "0x7c59a57fc16eac270421b74615c4bc009ecd486d";
const bankrollAddress = "0xEC45F47a6A7d86Fe407CA38F428029a8bD4Ae7A6";

function BankrollBalance() {
  const [balance, setBalance] = useState('');

  async function getBalance() {
    try {
      const tokenContract = new web3.eth.Contract(tokenABI, tokenAddress);
      const balanceWei = await tokenContract.methods.balanceOf(bankrollAddress).call();
      const balanceInEth = web3.utils.fromWei(balanceWei, 'ether');
      setBalance(balanceInEth);
    } catch (error) {
      console.error("Error fetching balance: ", error);
      setBalance('Error'); // Ovo će prikazati "Error" u interfejsu ako postoji problem
    }
  }

  useEffect(() => {
    getBalance();
    const intervalId = setInterval(getBalance, 600000); // Update balance every 10 minutes
    return () => clearInterval(intervalId);
  }, []);

  if (balance === '') {
    return <div>Loading balance...</div>;
  }

  const formattedBalance = new Intl.NumberFormat('de-DE', { 
    minimumFractionDigits: 0, 
    maximumFractionDigits: 0 
  }).format(balance);

  return (
    <div className="stake-balance-container d-flex justify-content-center align-items-center">
      <span className="stake-title">Bankroll:</span>
      <span className="stake-balance">{formattedBalance} RXCG</span>
      <a href={`https://bscscan.com/address/${bankrollAddress}`} 
         target="_blank" 
         rel="noopener noreferrer" 
         className="stake-verify-link btn btn-link">Verify</a>
    </div>
  );
}

export default BankrollBalance;
